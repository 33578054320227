import React, { useContext, useEffect, useRef, useState } from "react";
import BreadCrumb from "../shared/BreadCrumb";
import { LuNetwork } from "react-icons/lu";
import { AuthContext } from "../../AuthContext";
import therest from "../../therest";
import { UserContext } from "../../UserContext";
import Notification from "../shared/Notification";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import Modals from "../modals/Modals";
import { Toast } from "primereact/toast";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { CiRead } from "react-icons/ci";

const Teams = () => {
  const { apiToken, apiHeader } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const [viewUserModal, setViewUserModal] = useState(false);
  const [modalUserData, setModalUserData] = useState();
  const toast = useRef(null);

  const viewUserData = (rowData)=>{
    //get user data and show in modal or page
    setViewUserModal(true);
    getUserData(rowData.id);
  }

  const getUserData = (id) => {
    therest.get('/user/' + id, apiToken).then((response) => {
      if(response.status){
        setModalUserData(response.user);
      } else {
        setViewUserModal(false);
      }
    });
  }

  const viewUserDataOnHide = (isTableUpdate) => {
    setViewUserModal(false);
    setModalUserData();

    if(isTableUpdate){
      // fetchData();
      getTeams();
    }
  }
  const userModalTriggerMessage = (status, message) => {
    if(status){
      toast.current.show({ severity: 'success', summary: 'Success', detail: message });
    } else {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: message });
    }
  }

  const renderStatus = (rowData) => {
    return (
      
      <Tag className={`${(rowData.status == 3) ? 'bg-gray-500':''}`} severity={rowData.status == 1 ? 'success' : (rowData.status == 2 ? 'warning' : 'info')} value={(rowData.status==1 ?'Active':(rowData.status==2 ?'Pending':'Inactive'))} rounded></Tag>
    );
  }
  const viewUserDataOnSubmit = (data) => {
    therest.post('/user/update-user-referrer', data, apiToken).then((response) => {
      if(response.status){
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
      } else {
        toast.current.show({ severity: 'error', summary: 'Update Failed!', detail: 'Referred By is not valid.' });
      }
    });
  }

  const handleMemberClick = (id, tree_view = false) => {
    localStorage.removeItem('treeviewActive');
    if(tree_view){
      localStorage.setItem('treeviewActive', 'true');
    } 
    window.location.href = '/#/team/'+id;
  }

  const [teams, setTeams] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const getTeams = () => {
    therest.get('/teams', apiToken, apiHeader).then((response) => {
      if(response.status){
        console.log(response)
        setTeams(response);
        setFilteredMembers(response);
      } else {
        Notification('Failed', 'There was an unexpected error.', 'danger');
      }
    });
  }

  const renderViewLink = (rowData) => {
    // console.table(rowData)
    let treeview="";
    if(rowData.role_id!=3){
      treeview = <LuNetwork data-pr-tooltip="View Tree" data-pr-position="top" size={30} className="view-tree text-BrandGreen hover:bg-BrandGreen hover:text-white hover:rounded-full p-1" style={{zIndex: 1000}} onClick={() => handleMemberClick(rowData.id, true)}/> 
    }
    return  <div className="flex items-center justify-end gap-2">
      <Tooltip target=".view-user" />
      <CiRead data-pr-tooltip="View User" data-pr-position="top" size={30} className="view-user text-BrandBlue hover:bg-BrandBlue hover:text-white hover:rounded-full p-1" onClick={() => viewUserData(rowData)} />
      
      <Tooltip target=".view-tree" />
      {treeview}
      
      </div>
  }
  
  const renderMemberCount = (rowData)=>{
    // return rowData.myteam.length;
  }
  const renderImage = (rowData) => {
    return (
      <div className="text-center flex w-full justify-center">
        <img alt={rowData.name} className='border w-[100px] h-[100px] object-cover rounded-full' height="50" src={rowData.profile_url || "/person.png"}/> 
      </div>
    )
  }

  useEffect(() => {
    getTeams();
  }, []);

  const dt = useRef(null); 

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };
  
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    status: { value: '', matchMode: FilterMatchMode.EQUALS  }
  });

  const onGlobalFilterChange = (value, type = 'global') => {
    let _filters = { ...filters };

    _filters[type].value = value;
    setFilters(_filters);
  };

  const statuses = [
    { label: 'All', value: '' },
    { label: 'Active', value: '1' },
    { label: 'Pending', value: '2' },
    { label: 'Inactive', value: '3' },
  ];

  const header = (
    <div className="flex justify-between -mx-4">
      <div className="flex gap-4 w-1/2">
        <span className="p-input-icon-left flex items-center w-full">
          <i className="pi pi-search" />
          <InputText className="w-full ring-0 border rounded-full pl-10 pr-4 py-3 font-normal" value={filters.global.value} onChange={(e) => onGlobalFilterChange(e.target.value, 'global')} placeholder="Search" />
        </span>
        <Dropdown value={filters.status.value} onChange={(e) => onGlobalFilterChange(e.value, 'status')} className="w-full ring-0 border rounded-full px-4" options={statuses} optionLabel="label" placeholder="Status" />

      </div>
      <Tooltip target=".user-list" />
      <Button className='user-list bg-BrandBlue text-white px-4 py-2'  type="button" icon="pi pi-download" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
    </div>
  );

  return(
    <>
      <Toast ref={toast} />
      <Modals type='user' data={modalUserData} open={viewUserModal} onSubmit={viewUserDataOnSubmit} onHide={viewUserDataOnHide} triggerMessage={userModalTriggerMessage} />
      <BreadCrumb links={[{title: 'Teams', link: '/teams'}]}/>
      <div className="w-full min-h-[50%] relative bg-white rounded-lg mt-8">

        <div className="flex md:justify-between lg:flex-row flex-col w-full p-4 border-b-2 border-sky-600">
          <div className="flex justify-start lg:justify-between gap-4 lg:p-8 p-4">
            <div>
              <img alt={user.name} className="h-[100px] object-cover rounded-full w-[100px]" src={user.profile_url || "/person.png"}/>
            </div>
            <div className="p-2 flex justify-center flex-col">
              <h1 className="font-bold text-lg">{user.name}</h1>
              <p>Team Leader</p>
            </div>
            <div className="p-2 flex justify-center flex-col">
              {(user.role_id!=3) ? <LuNetwork style={{zIndex: 1000}} onClick={() => handleMemberClick(user.id, true)}/> : ''}
              
            </div>
            {/* <div className="w-[100px] h-[100px]">
              <img alt={''} className="rounded-lg" src="/person.png"/>
            </div> */}
            {/* <div className="p-2 flex justify-center flex-col ">
              <h1 className="text-sky-600 font-bold lg:text-[32px] text-lg">{'Team ' +  user.name}</h1>
              <p>A short team description here</p>
            </div> */}
          </div>
 
          <div className="flex gap-4 lg:p-8 p-4 lg:w-1/2">
            {/* {
              teams && teams.team && (
              <>
                <div className="w-[100px] h-[100px]">
                  <img alt={teams.team.name} className="h-[100px] object-cover rounded-full w-[100px]" src={teams.team.profile_url || "https://placehold.co/100"}/>
                </div>
                <div className="p-2 flex justify-center flex-col">
                  <h1 className="font-bold lg:text-[32px] text-lg">{teams.team.name}</h1>
                  <p>Team Leader</p>
                </div>
                <div className="p-2 flex justify-center flex-col">
                  <LuNetwork style={{zIndex: 1000}} onClick={() => handleMemberClick(teams.team.id, true)}/>
                </div>
              </> 
              )
            } */}

            {/* {
              teams && teams.team == null && (
              <>
                <div>
                  <img alt={user.name} className="h-[100px] object-cover rounded-full w-[100px]" src={user.profile_url || "https://placehold.co/100"}/>
                </div>
                <div className="p-2 flex justify-center flex-col">
                  <h1 className="font-bold text-lg">{user.name}</h1>
                  <p>Team Leader</p>
                </div>
                <div className="p-2 flex justify-center flex-col">
                  <LuNetwork style={{zIndex: 1000}} onClick={() => handleMemberClick(user.id, true)}/>
                </div>
              </> 
              )
            } */}
            {/* <div>
              <img alt={user.name} className="h-[100px] object-cover rounded-full w-[100px]" src={user.profile_url || "/person.png"}/>
            </div>
            <div className="p-2 flex justify-center flex-col">
              <h1 className="font-bold text-lg">{user.name}</h1>
              <p>Team Leader</p>
            </div>
            <div className="p-2 flex justify-center flex-col">
              <LuNetwork style={{zIndex: 1000}} onClick={() => handleMemberClick(user.id, true)}/>
            </div> */}
          </div>
        </div>
        <div className='w-full p-4 lg:p-8'>
          <div className="flex flex-col pb-4 lg:pb-8">
            <div className="">
              <DataTable ref={dt} header={header} filters={filters} className="bg-transparent" value={teams.members} size={'medium'} paginator rows={10} rowsPerPageOptions={[10, 50, 100]} tableStyle={{ minWidth: '50rem' }} stripedRows>
                <Column headerClassName="border-b" header="" className='w-[150px]' body={renderImage} />
                <Column headerClassName="border-b" field="name" sortable header="Member Name" />
                <Column headerClassName="border-b" field="email" sortable header="Email" />
                <Column headerClassName="border-b" field="referral_code" sortable header="Referral Code" />
                <Column headerClassName="border-b"  body={renderStatus} sortable header="Status" />
                {/* <Column headerClassName="border-b" body={renderMemberCount} header="Total Members" /> */}
                <Column headerClassName="border-b" header="" body={renderViewLink} />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Teams;