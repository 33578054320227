import React, { useContext, useEffect, useState, Fragment, useRef } from "react";
import BreadCrumb from "../shared/BreadCrumb";
import { AuthContext } from "../../AuthContext";
import therest from "../../therest";
import { FaUsers, FaStar,FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import Notification from "../shared/Notification";
import Modal from "../modals/Modal";
import { InputSwitch } from "primereact/inputswitch";

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import Modals from "../modals/Modals";
import { LoadingContext } from "../../contexts/LoadingContext";

import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { InputText } from "primereact/inputtext";

import { InputTextarea } from 'primereact/inputtextarea';
        
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { TabPanel, TabView } from "primereact/tabview";

import { CiTrash, CiRead, CiCoinInsert,CiCircleCheck } from "react-icons/ci";
import { Tooltip } from "primereact/tooltip";

const Users = () => {
  const { apiToken, apiHeader } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [totals, setTotals] = useState([]);
  const [description, setDescription] = useState();
  const [remarks, setRemarks] = useState('');
  // const [data,setData]=useState(["parent","user"])
  const toast = useRef(null);
  const fetchData = (filter = null) => {
    var params = ''
    if(filter){
      params = `?filter=${filter}`
    }
    console.log(params);
    therest.get('/users' + params, apiToken, apiHeader)
    .then(response => {
      setUsers(response.users);
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  const fetchCardsData = () => {
    therest.get('/users/card-totals', apiToken, apiHeader)
    .then(response => {
      setTotals(response.totals);
    }).catch(error => {
      console.error('Error:', error);
    });
  }
  
  const { setIsShowLoading } = useContext(LoadingContext);

  const [showUsersTable, setShowUsersTable] = useState(false);
  const [selectedStatusOption, setSelectedStatusOption] = useState('');
  const handleChange = (e) => {
    setSelectedStatusOption(e);
  }; 
  const statusOptions = [
    { value: '', label: 'All'},
    { value: '1', label: 'Active'},
    { value: '2', label: 'Pending'},
    { value: '3', label: 'Inactive'}
  ];
  
  const cardClick = (filter) => {
    setShowUsersTable(true);
    fetchData(filter);
  }

  const [amount, setAmount] = useState(0);
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  }
  const paymentMethods = [
    { value: 1, label: 'Cash'},
    { value: 2, label: 'Bank Transfer'},
    { value: 3, label: 'Gcash'},
    { value: 4, label: 'Maya'},
    { value: 5, label: 'Palawan'},
    { value: 6, label: 'Cebuana'}
  ];
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0]);
  const handlePaymentMethodChange = (e) => {
    setSelectedPaymentMethod(e);
  }; 

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const handleMakePayment = (user) => {
    setSelectedUser(user);
    setOpenPaymentModal(true);
  }
  
  const [viewUserModal, setViewUserModal] = useState(false);
  const [modalUserData, setModalUserData] = useState();
  const getUserData = (id) => {
    // console.log(id)
    therest.get('/user/' + id, apiToken).then((response) => {
      console.log(response);
      if(response.status){
        setModalUserData(response.user);
      } else {
        setViewUserModal(false);
      }
    });
  }
  const viewUserData = (rowData)=>{
    //get user data and show in modal or page
    
    setViewUserModal(true);
    getUserData(rowData.id);
  }
  const viewUserDataOnHide = (isTableUpdate) => {
    setViewUserModal(false);
    setModalUserData();

    if(isTableUpdate){
      fetchData();
    }
  }

  const userModalTriggerMessage = (status, message) => {
    if(status){
      toast.current.show({ severity: 'success', summary: 'Success', detail: message });
    } else {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: message });
    }
  }

  const viewUserDataOnSubmit = (data) => {
    therest.post('/user/update-user-referrer', data, apiToken).then((response) => {
      if(response.status){
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
      } else {
        toast.current.show({ severity: 'error', summary: 'Update Failed!', detail: 'Referred By is not valid.' });
      }
    });
  }

  const [proofOfPayment, setProofOfPayment] = useState(null);
  const [proofName, setProofName] = useState('');
  const handleProofOfPaymentUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProofOfPayment(reader.result);
        setProofName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleModalClose = () => {
    setSelectedPaymentMethod(paymentMethods[0]);
    setProofOfPayment('');
    setProofName('');
    setAmount('');
    setSelectedUser();
    setOpenPaymentModal(false);
    setIsShowLoading(false);
  }

  const handlePaymentSubmit = () => {
    setIsShowLoading(true);
    var data = {
      id: selectedUser.id,//user id
      parentid:selectedUser.parent_referral,
      amount: selectedUser.product_price,
      trans_type: 1,//payment transaction
      payment_method: selectedPaymentMethod.value,
      description: "Payment for " + selectedUser.product_name,
      proof_of_payment: proofOfPayment,
      product_purchase_id: selectedUser.product_purchase_id,
      remarks: remarks,
    }
    console.log('data:::', data);
    therest.post('/transaction/make-payment', data, apiToken).then((response) => {
      console.log(response);
      if(response.status){
        fetchData();
        fetchCardsData();
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Transaction Completed!' });
        // Notification('Success', 'Transaction completed', 'success');
        setIsShowLoading(false);
        setRemarks('');
      } else {
        // Notification('Error', response.message, 'danger');
        toast.current.show({ severity: 'error', summary: 'Failed!', detail: response.message,data: response.data }); 
        setIsShowLoading(false);
      }
      handleModalClose();
      setIsShowLoading(false);
    });
  }

  useEffect(() => {
    fetchData();
    fetchCardsData();
  }, []);

  const renderProfile = (rowData) => {
    return(
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 rounded-full" src={`${rowData.profile_url || '/person.png'}`} alt={rowData.name} />
        </div>
        <div className="ml-4">
          <div className="text-sm leading-5 font-medium text-gray-900">{rowData.name}</div>
          {/* <div className="text-sm leading-5 text-gray-500">{rowData.email}</div> */}
        </div>
      </div>
    );
  }

  const renderTotalCommissions = (rowData) => {
    return (
      <div className="text-left">{Number(rowData.total_commissions).toFixed(2)}</div>
    )
  }

  const renderStatus = (rowData) => {
    return (
      
      <Tag className={`${(rowData.status == 3) ? 'bg-gray-500':''}`} severity={rowData.status == 1 ? 'success' : (rowData.status == 2 ? 'warning' : 'info')} value={(rowData.status==1 ?'Active':(rowData.status==2 ?'Pending':'Inactive'))} rounded></Tag>
    );
  }
  const renderEmail = (rowData) => {
    return (
      <div className="text-left">{rowData.email}</div>
    );
  }
  const renderReferralCode = (rowData) => {
    return (
      <div className="text-left">{rowData.referral_code}</div>
    );
  }

  const renderReferredBy =(rowData)=>{
    return (
      <div className="text-left">{rowData.parent_referral}</div>
    );
  }
  const [statuses, setStatuses] = useState(true);

  // const handleSwitchStatusChange = (keyToUpdate, value) => {
  //   setStatuses((prevStatuses) => ({
  //     ...prevStatuses,
  //     [keyToUpdate]: value
  //   }));
  // }
  const deactivateUser = (user) => {
    console.log(user);
    // var id =selectedUser.id
    therest.post('/user/updateuserstatus', user, apiToken).then((response) => {
     
      console.log(response);
      if(response.status){
        fetchData();
        fetchCardsData();
        // Notification('Success', 'User deactivated', 'success');
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'User Deactivated!' });
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed!', detail: 'There was an error submitting payment.' });
        // Notification('Error', 'There was an error submitting payment.', 'danger');
      }
      // handleModalClose();
    });
    
  }

  const ActivateUser = (user) => {
    // console.log(user);
    // var id =selectedUser.id
    therest.post('/influencers/approve', user, apiToken).then((response) => {
     
      console.log(response);
      if(response.status){
        fetchData();
        fetchCardsData();
        // Notification('Success', 'User deactivated', 'success');
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Influencer Activated' });
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed!', detail: 'There was an error submitting payment.' });
        // Notification('Error', 'There was an error submitting payment.', 'danger');
      }
      // handleModalClose();
    });
    
  }

  const handleChangeUserStatus=(rowData,status)=>{

    if(status==1){
      ActivateUser(rowData);
    }else{
      confirmDialog({
        message: 'Do you want to deactivate this user?',
        header: 'Deactivate Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'mx-1 px-4 py-2 text-sm font-semibold rounded-sm text-white bg-red-700',
        rejectClassName: 'mx-1 px-4 py-2 text-sm font-semibold rounded-sm text-black bg-transparent',
        accept:()=>deactivateUser(rowData),
        // reject
      });
    }
    
  }
  
  const renderPaymentButton = (rowData) => {
    // console.log(rowData);
    let paymentbutton;
    if(rowData.role_id!=3){
      paymentbutton = <CiCoinInsert data-pr-tooltip="Make Payment" data-pr-position="top"  size={30} className="make-payment text-BrandGreen hover:bg-BrandGreen hover:text-white hover:rounded-full p-1" onClick={() => handleMakePayment(rowData,0)} />
    }else{
      paymentbutton = <CiCircleCheck data-pr-tooltip="Approve user" data-pr-position="top"  size={30} className="make-payment text-BrandGreen hover:bg-BrandGreen hover:text-white hover:rounded-full p-1" onClick={() => handleChangeUserStatus(rowData,1)} />
    }
    return rowData.status == 2 ?
    (
      <>
        <div className="flex justify-end items-center gap-2">
          {/* <Button label="Make Payment" className="px-4 py-2 text-sm font-semibold rounded-lg bg-BrandGreen text-white" severity="success" rounded onClick={() => handleMakePayment(rowData)} /> */}
          <Tooltip target=".view-user" />
          <CiRead data-pr-tooltip="View User" data-pr-position="top" size={30} className="view-user text-BrandBlue hover:bg-BrandBlue hover:text-white hover:rounded-full p-1" onClick={() => viewUserData(rowData)} />
          <Tooltip target=".make-payment" />
          {paymentbutton}
          
          <Tooltip target=".update-user"/> 
          <CiTrash data-pr-tooltip="Deactivate User" data-pr-position="left" size={30} className="update-user text-red-500 hover:bg-red-500 hover:text-white hover:rounded-full p-1" onClick={() => handleChangeUserStatus(rowData,0)} />
        </div>
        
      </>
    ) : <>  
      <div className="flex justify-end items-center gap-2">
        <Tooltip target=".view-user" />
        <CiRead data-pr-tooltip="View User" data-pr-position="top" size={30} className="view-user text-BrandBlue hover:bg-BrandBlue hover:text-white hover:rounded-full p-1" onClick={() => viewUserData(rowData)} />
        
        <Tooltip target=".update-user"/> 
        <CiTrash data-pr-tooltip="Deactivate User" data-pr-position="left" size={30} className="update-user text-red-500 hover:bg-red-500 hover:text-white hover:rounded-full p-1" onClick={() => handleChangeUserStatus(rowData,0)} />
        
      </div>
    </>;
  }

  const dt = useRef(null); 

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    status: { value: '', matchMode: FilterMatchMode.EQUALS  }
  });

  const onGlobalFilterChange = (value, type = 'global') => {
    let _filters = { ...filters };

    _filters[type].value = value;

    console.log(value, type);
    setFilters(_filters);
  };

  const header = (
    <div className="flex justify-between -mx-4">
      <div className="flex gap-4 w-1/2">
        <span className="p-input-icon-left flex items-center w-full">
          <i className="pi pi-search" />
          <InputText className="w-full ring-0 border rounded-full pl-10 pr-4 py-3 font-normal" value={filters.global.value} onChange={(e) => onGlobalFilterChange(e.target.value, 'global')} placeholder="Search" />
        </span>
        <Dropdown value={filters.status.value} onChange={(e) => onGlobalFilterChange(e.value, 'status')} className="w-full ring-0 border rounded-full px-4" options={statusOptions} optionLabel="label" 
          placeholder="Status" />
      </div>
      <Tooltip target=".user-list" />
      <Button className='user-list bg-BrandBlue text-white px-4 py-2'  type="button" icon="pi pi-download" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
    </div>
  );

  const [influencerFilters, setInfluencerFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    // status: { value: '', matchMode: FilterMatchMode.EQUALS  }
  });

  const onInfluencerFilterChange = (value, type = 'global') => {
    let _filters = { ...influencerFilters };

    _filters[type].value = value;

    console.log(value, type);
    setInfluencerFilters(_filters);
  };

  const influencerhHeader = (
    <div className="flex justify-between -mx-4">
      <div className="flex gap-4 w-1/2">
        <span className="p-input-icon-left flex items-center w-full">
          <i className="pi pi-search" />
          <InputText className="w-full ring-0 border rounded-full pl-10 pr-4 py-3 font-normal" value={influencerFilters.global.value} onChange={(e) => onInfluencerFilterChange(e.target.value, 'global')} placeholder="Search" />
        </span>
        {/* <Dropdown value={influencerFilters.status.value} onChange={(e) => onInfluencerFilterChange(e.value, 'status')} className="w-full ring-0 border rounded-full px-4" options={statusOptions} optionLabel="label" 
          placeholder="Status" /> */}
      </div>
      <Tooltip target=".influencer-list" />
      <Button className='influencer-list bg-BrandBlue text-white px-4 py-2' type="button" icon="pi pi-download" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
    </div>
  );
  

  const [influencers, setInfluencers] = useState();
  const [influencersTabLoading, setInfluencersTabLoading] = useState(false);
  const onBeforeTabChanges = (e) => {
    const { index, originalEvent } = e;
    if(index == 1 ){ // withdrawal requests tab index
      getInfluencers();
    }
  }

  const getInfluencers = () => {
    setInfluencersTabLoading(true);
    therest.get('/influencers', apiToken, apiHeader).then((response) => {
      if(response.status){
        setInfluencers(response.users);
      }
      setInfluencersTabLoading(false);
    });
  }

  return(
    <>
      <Modals type='user' data={modalUserData} onSubmit={viewUserDataOnSubmit} open={viewUserModal} onHide={viewUserDataOnHide} triggerMessage={userModalTriggerMessage} />
      <Toast ref={toast} />
      <ConfirmDialog />
      <BreadCrumb links={[{title: 'Users', link: '/users'}]}/>
      <div className="w-full mt-8">
        <div className='grid md:grid-cols-4 gap-8'>
          <div className='px-4 pt-4 pb-10 w-full bg-white rounded-lg flex-col items-center cursor-pointer' onClick={() => cardClick(0)}>
            <div className="flex-col justify-start items-center gap-6 flex">
              <div className="text-center w-full">
                <div className="text-zinc-800 text-base font-bold leading-none">Total Users</div>
              </div>
              <div className="flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch justify-center items-start gap-2 inline-flex">
                  <div className="text-center text-pink-400 text-[40px] font-normal leading-10"><FaUsers/></div>
                  <div className="text-center text-pink-400 text-[40px] font-bold leading-10">{totals.total_users}</div>
                </div>
                <div className="text-center text-neutral-700 text-2xl font-semibold leading-normal">members</div>
              </div>
            </div>
          </div>

          <div className='px-4 pt-4 pb-10 w-full bg-white rounded-lg flex-col items-center cursor-pointer' onClick={() => cardClick(2)}>
            <div className="flex-col justify-start items-center gap-6 flex">
              <div className="text-center w-full">
                <div className="text-zinc-800 text-base font-bold leading-none">Pending Users</div>
              </div>
              <div className="flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch justify-center items-start gap-2 inline-flex">
                  <div className="text-center text-emerald-400 text-[40px] font-normal leading-10"><FaUsers/></div>
                  <div className="text-center text-emerald-400 text-[40px] font-bold leading-10">{totals.pending_users}</div>
                </div>
                <div className="text-center text-neutral-700 text-2xl font-semibold leading-normal">members</div>
              </div>
            </div>
          </div>

          <div className='px-4 pt-4 pb-10 w-full bg-white rounded-lg flex-col items-center cursor-pointer' onClick={() => cardClick(2 + '&approval=true')}>
            <div className="flex-col justify-start items-center gap-6 flex">
              <div className="text-center w-full">
                <div className="text-zinc-800 text-base font-bold leading-none">Pending Approval Users</div>
              </div>
              <div className="flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch justify-center items-start gap-2 inline-flex">
                  <div className="text-center text-emerald-400 text-[40px] font-normal leading-10"><FaUsers/></div>
                  <div className="text-center text-emerald-400 text-[40px] font-bold leading-10">{totals.pending_approval}</div>
                </div>
                <div className="text-center text-neutral-700 text-2xl font-semibold leading-normal">members</div>
              </div>
            </div>
          </div>

          <div className='px-4 pt-4 pb-10 w-full bg-white rounded-lg flex-col items-center cursor-pointer' onClick={() => cardClick(1)}>
            <div className="flex-col justify-start items-center gap-6 flex">
              <div className="text-center w-full">
                <div className="text-zinc-800 text-base font-bold leading-none">Total Active</div>
              </div>
              <div className="flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch justify-center items-start gap-2 inline-flex">
                  <div className="text-center text-orange-400 text-[40px] font-normal leading-10"><FaStar/></div>
                  <div className="text-center text-orange-400 text-[40px] font-bold leading-10">{totals.total_active}</div>
                </div>
                <div className="text-center text-neutral-700 text-2xl font-semibold leading-normal">members</div>
              </div>
            </div>
          </div>
          
        </div> 
      </div>
      {
        showUsersTable && (
          <div className="w-full min-h-[50%] relative bg-white rounded-lg mt-8">
            <div className="w-full gap-4 flex items-center p-4 lg:p-8">
              <h1 className="lg:text-4xl text-xl font-bold text-sky-600">Users</h1>
            </div>
            <div className="flex flex-col px-4 pb-4 lg:px-8 lg:pb-8">
              <TabView panelContainerClassName="px-4 border" onBeforeTabChange={onBeforeTabChanges}>
                <TabPanel pt={{
                  headerAction: ({ parent }) => ({
                    className: parent.state.activeIndex === 0 ? 'bg-BrandGreen text-white' : ''
                  })
                }} key={'1-1'} header={'Members'}>
                  <DataTable ref={dt} header={header} filters={filters} className="bg-transparent" value={users.filter((d,i) => ( selectedStatusOption == null || selectedStatusOption == '' ? true : (d.status == (selectedStatusOption && selectedStatusOption.value) || '') ) )} size={'medium'} paginator rows={10} rowsPerPageOptions={[10, 50, 100]} tableStyle={{ minWidth: '50rem' }} stripedRows>
                    <Column headerClassName="border-b" field="name" sortable header="Name" body={renderProfile}/>
                    <Column headerClassName="border-b" field="email" sortable header="Email" body={renderEmail}/>
                    <Column headerClassName="border-b" field="referral_code" sortable header="User ID" body={renderReferralCode} />
                    <Column headerClassName="border-b" field="referredbyname" sortable header="Referrer Name" />
                    <Column headerClassName="border-b" field="referredby" sortable header="Referrer Code" />
                    <Column headerClassName="border-b" field="total_commissions" sortable header="Total Commissions" body={renderTotalCommissions}/>
                    <Column headerClassName="border-b" field="status" sortable header="Status" body={renderStatus} />
                    <Column headerClassName="border-b" header="" body={renderPaymentButton} />
                  </DataTable>

                </TabPanel>
                <TabPanel pt={{
                  headerAction: ({ parent }) => ({
                    className: parent.state.activeIndex === 1 ? 'bg-BrandGreen text-white' : ''
                  })
                }} key={'1-1'} header={'Influencers'}>
                  {
                    influencersTabLoading && (
                      <div className="h-[400px]">
                        Loading...
                      </div>
                    )
                  }

                  {
                    !influencersTabLoading && (
                      <>
                        <DataTable ref={dt} header={influencerhHeader} filters={influencerFilters} className="bg-transparent" value={influencers} size={'medium'} paginator rows={10} rowsPerPageOptions={[10, 50, 100]} tableStyle={{ minWidth: '50rem' }} stripedRows>
                          <Column headerClassName="border-b" field="name" sortable header="Name" body={renderProfile}/>
                          <Column headerClassName="border-b" field="email" sortable header="Email" body={renderEmail}/>
                          <Column headerClassName="border-b" field="referral_code" sortable header="User ID (Referral Code)" body={renderReferralCode} />
                          <Column headerClassName="border-b" field="members.length" sortable header="Total Members"/>
                          <Column headerClassName="border-b" field="status" sortable header="Status" body={renderStatus} />
                          <Column headerClassName="border-b" header="" body={renderPaymentButton} />
                        </DataTable>
                      </>
                    )
                  }
                </TabPanel>
              </TabView>
            </div>
          </div>
        )
      }
      
      <Modal 
        openModal={openPaymentModal} 
        onClose={() => {}} // empty prevents outside click
        onCancel={handleModalClose} 
        onSubmit={handlePaymentSubmit}
        title={`Making payment for ${selectedUser && selectedUser.name || ''}`}
        children={
          <div className="mt-8 flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="amount">Package</label>
                <input type="text" value={selectedUser && selectedUser.product_name} disabled className="w-full appearance-none bg-gray-50 border-[1px] border-gray-300 text-gray-900 rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4 cursor-not-allowed select-none"/>
              </div>
              <div>
                <label htmlFor="amount">Amount to Pay</label>
                <input type="text" value={selectedUser && selectedUser.product_price} disabled className="w-full appearance-none bg-gray-50 border-[1px] border-gray-300 text-gray-900 rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4 cursor-not-allowed select-none"/>
              </div>
              <div>
                <label htmlFor="paymentMethod">Payment Method</label>
                <Select
                  id="paymentMethod"
                  options={paymentMethods} 
                  isClearable={true}
                  value={selectedPaymentMethod}
                  className="block w-full"
                  onChange={handlePaymentMethodChange}
                  name="Status"
                  placeholder="Status"
                  styles={{
                    control: (styles) => ({ 
                      ...styles, 
                      backgroundColor: 'transparent',
                      borderRadius: '9999px',
                      padding: '10px 10px',
                    }),
                    indicatorSeparator: (styles) => ({
                      ...styles,
                      width: 0,
                    }),
                  }}
                />
              </div>
              {/* <div>
                <label htmlFor="description">Description</label>
                <input type="text" value={description} className="w-full border-[1px] border-gray-300 text-gray-900 rounded-full ring-0 block w-full p-4 px-4 select-none"/>
              </div> */}
              <div className="col-span-2">
                <label htmlFor="description">Remarks</label>
                <InputTextarea value={remarks} onChange={(e) => setRemarks(e.target.value)} className="w-full border-[1px] border-gray-300 ring-0 px-4 py-3" rows={4} cols={30}/>
              
              </div>
            </div>
            
            {/* <div>
              <label htmlFor="amount">Amount Paid</label>
              <input type="number" value={amount} min={0} onChange={handleAmountChange} className="w-full appearance-none bg-gray-50 border-[1px] border-gray-300 text-gray-900 rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4"/>
            </div> */}
            <div>
              <label htmlFor="amount">Proof of payment</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleProofOfPaymentUpload}
                style={{ display: 'none' }}
                id="proof_of_payment"
              />
              <p className="mt-4 text-center">{proofName}</p>
              <label htmlFor="proof_of_payment" className="block border text-center border-BrandGreen text-BrandGreen px-4 py-2 w-full mt-4 rounded-full hover:bg-BrandGreen hover:text-white cursor-pointer" >Browse File</label>
            </div>
          </div>
        }
      />
    </>
  )
}

export default Users;